import Timer from "../technicalComponents/Timer";
import Category from "../technicalComponents/Category";
import {useNavigate} from "react-router-dom";
import newsPaperIcon from '../../img/icons/newsPaperIcon.svg';
import newsPaperIconDark from '../../img/icons/newsPaperIconDark.svg';
import arrowIcon from '../../img/icons/greenArrow.svg';
import '../../sass/articleImageVersion.css';
import React, {useContext, useEffect, useState} from "react";

import {ThemeContext} from "../context/ThemeContext";
import placeHolder from '../../img/ErrorFetchingImage.png';
import placeHolderDark from '../../img/ErrorFetchingImageDark.png';
import arrow from "../../img/icons/greenArrow.svg";


const ArticleImageVersion = ({post}) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
    const {theme} = useContext(ThemeContext);
    const navigate = useNavigate();
    const placeHolderImage = theme === 'light' ? placeHolder : placeHolderDark;

    const handleRedirect = () => {
        navigate(`/post/${post.itemId}`);
    };

    const handleViewAllSources = (event) => {
        event.stopPropagation();
        navigate(`/post/${post.itemId}/sources`);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getVerbForm = (number) => {
        const lastTwoDigits = number % 100;
        const lastDigit = number % 10;

        if (number === 1) {
            return 'Pisze';
        } else if (lastTwoDigits >= 12 && lastTwoDigits <= 14) {
            return 'Pisze';
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            return 'Piszą';
        } else {
            return 'Pisze';
        }
    };

    const getNounForm = (number) => {
        const lastTwoDigits = number % 100;
        const lastDigit = number % 10;

        if (number === 1) {
            return 'źródło';
        } else if (lastTwoDigits >= 12 && lastTwoDigits <= 14) {
            return 'źródeł';
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            return 'źródła';
        } else {
            return 'źródeł';
        }
    };


    return (
        <article className="article-container">
            <div className="articleContent" onClick={handleRedirect}>
                <div className="articleContent-mainInfo">
                    <Category category={post.category}></Category>
                    <h2>{post.title}</h2>
                    <Timer creationDate={post.creationDate} customStyle={!isMobile ? "withMargin" : ""}/>
                    <p className="articleContent-text">
                        {getVerbForm(post.sourceInfo.numberOfSource)} o tym <span>{post.sourceInfo.numberOfSource}</span> {getNounForm(post.sourceInfo.numberOfSource)}:
                    </p>
                </div>
                <div className="articleContent-grid">
                    <div className="articleContent-grid-mainSource">
                        {post.sourceInfo.sources[0] && (() => {
                            const formattedSourceName = post.sourceInfo.sources[0].sourceName
                                .toLowerCase()
                                .replace(/ /g, '');
                            const sourceImage = `https://storage.googleapis.com/image.verita.news/sources/${formattedSourceName}.png`;
                            return (
                                <a href={post.sourceInfo.sources[0].redirectLink} target="_blank"
                                   rel="noopener noreferrer">
                                    <div className="articleContent-imageWrapper">
                                        <img className="articleContent-grid-mainSource-image"
                                             src={post.sourceInfo.sources[0].sourceImageUrl}
                                             alt={post.sourceInfo.sources[0].sourceName} onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = placeHolderImage
                                        }}/>
                                    </div>
                                    <div className="articleContent-grid-mainSource-companyWrapper">
                                        <img className="companyLogo" src={sourceImage} alt={`${sourceImage} logo`}
                                             onError={(e) => {
                                                 e.target.onerror = null;
                                                 e.target.src = "https://storage.googleapis.com/image.verita.news/sources/notFound.png";
                                             }}/>
                                        <p>{post.sourceInfo.sources[0].sourceName}</p>
                                    </div>
                                    <h4>{post.sourceInfo.sources[0].title}</h4>
                                </a>
                            );
                        })()}
                    </div>
                    <div className="articleContent-grid-otherSources">
                        {post.sourceInfo.sources.slice(1, 4).map((source) => {
                            const formattedSourceName = source.sourceName
                                .toLowerCase()
                                .replace(/ /g, '');
                            const sourceImage = `https://storage.googleapis.com/image.verita.news/sources/${formattedSourceName}.png`;

                            return (
                                <div className="articleContent-grid-sources-element" key={source.sourceId}>
                                    <a href={source.redirectLink} target="_blank" rel="noopener noreferrer">
                                        <div className="articleContent-imageWrapper">
                                            <img className="articleContent-grid-sources-element-imgOnlyMobile"
                                                 src={source.sourceImageUrl} alt={source.sourceName} onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = placeHolderImage
                                            }}/>
                                        </div>
                                        <div className="articleContent-grid-sources-element-companyWrapper">
                                            <img className="companyLogo" src={sourceImage}
                                                 alt={`${source.sourceName} logo`} onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "https://storage.googleapis.com/image.verita.news/sources/notFound.png";
                                            }}/>
                                            <p className="articleContent-grid-sources-element__sourceName">{source.sourceName}</p>
                                        </div>
                                        <h4 className="articleContent-grid-sources-element__title">{source.title}</h4>
                                    </a>
                                </div>
                            );
                        })}
                        {isMobile && (
                            <div className="articleContent-grid-sources-element-showAllBig" key="show all"
                                 onClick={handleViewAllSources}>
                                <div className="articleContent-sources-element-banner-wrapper">
                                    <div className="articleContent-sources-element-banner">
                                        <p>Zobacz wszystkie materiały</p>
                                        <img src={arrowIcon} alt="arrow icon"/>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="articleContent-grid-otherSources-showAll" onClick={handleViewAllSources}>
                            <div className="articleContent-grid-otherSources-showAll-textAndIcon">
                                <img src={theme === 'light' ? newsPaperIcon : newsPaperIconDark} alt="newsPaperIcon"/>
                                <p>Zobacz wszystkie materiały</p>
                            </div>
                            <img className="arrowIconAllSources" src={arrowIcon}
                                 alt="arrow icon suggesting that user can click it"/>
                        </div>
                    </div>
                </div>
                <div className="showMore-wrapper">
                    <p className="showMore-text">Dowiedz się więcej</p>
                    <img className="showMore-img" src={arrow} alt="arrow icon"/>
                </div>
            </div>
        </article>
    );

};

export default ArticleImageVersion;